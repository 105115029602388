import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRippleEffect, IonRouterLink, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../contexts/AuthProvider';
import { useUtility } from '../../contexts/UtilityContext';
import { RestApi } from '../../services/RestApi';
import './Auth.scss';

export const Login: React.FC = () => {
  const { authLogin } = useAuth();
  const [inputs, setInputs] = useState({ email: '', password: '' });
  const history = useHistory();
  const { showLoading, hideLoading, showAlert, showToast } = useUtility();
  const onInputchange = (event: any) => {
    setInputs((prevState: any) => {
      return ({
        ...prevState,
        [event.target.name]: event.target.value
      });
    });
  };


  const doLogin = () => {
    try {
      showLoading();
      RestApi('login', inputs).then((user: any) => {
        hideLoading();
        authLogin(user);
        history.push('/dashboard');
      }).catch((message) => {
        hideLoading();
        showAlert(message, 'Login');
      });
    } catch (err: any) {
      showToast(err.message);
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle className="login-title">
              Log in
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="auth-container">
          <IonCard class="card ion-margin-top">
            <IonCardHeader>
              <IonCardTitle class="ion-text-center">Fill the form to log in</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput type="email" name="email" clearInput placeholder="Your Email" onIonChange={onInputchange} required />
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type="password" name="password" clearInput placeholder="Your password" onIonChange={onInputchange} required />
              </IonItem>
              <IonGrid class="ion-margin-top">
                <IonRow>
                  <IonCol size-sm="6" size-xs="6" class="ion-text-left">
                    <IonRouterLink color="primary" href={'/auth/register'} class="ios ion-activatable hydrated">Create Account</IonRouterLink>
                  </IonCol>
                  <IonCol size-sm="6" size-xs="6" class="ion-text-right">
                    <IonRouterLink color="primary" href={'/auth/forgot-password'} class="ios ion-activatable hydrated">Forgot password</IonRouterLink>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <IonFooter class="ion-padding ion-no-border">
        <IonButton color="tertiary" className="ion-activatable ripple-parent" expand="block" onClick={doLogin}>Log in
          <IonRippleEffect type="unbounded"></IonRippleEffect>
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};
