import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonImg, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../contexts/AuthProvider';
import { useUtility } from '../contexts/UtilityContext';
import { ProductModel } from '../models/ProductModel';

export const ProductGridView: React.FC<{ product: ProductModel }> = ({ product }) => {
  const { addCart } = useAuth();
  const history = useHistory();
  const { showConfirm } = useUtility();
  const [qty, setQty] = useState(1);
  const qtyChange = (e: any) => {
    setQty(e.target.value);
  };
  const addToCart = (product: any) => {
    let p = { ...product };
    p.qty = qty;
    addCart(p);
    showConfirm('Product has been added to cart, Want to show cart ?', 'Cart').then(() => {
      history.push('/cart');
    });
  };
  const updateQty = (incDec: any) => {
    let q = qty;
    if (incDec == '-') {
      q--;
    } else {
      q++;
    }
    if (q <= 0) {
      q = 1;
    }
    setQty(q);
  };


  return (
    <IonCard>
      <IonImg src={product.image} />
      <IonCardHeader>
        <IonCardTitle>{product.product_name}</IonCardTitle>
        <IonRow>
          <IonCol className="qty-selector">
            <button onClick={() => updateQty('-')}>-</button>
            <IonInput type="number" name="qty" value={qty} onIonChange={(e) => qtyChange(e)} clearInput required min="1" />
            <button onClick={() => updateQty('+')}>+</button>
          </IonCol>
          <IonCol>
            <IonButton fill="outline" onClick={() => addToCart(product)}>Add to Cart</IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
        </IonRow>

        {/* <IonCardSubtitle>
          <IonItem>
            <IonLabel>Quantity</IonLabel>
            <IonSelect value="" placeholder="Select One">
              <IonSelectOption value="female">Female</IonSelectOption>
              <IonSelectOption value="male">Male</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonButton fill="outline" onClick={() => addToCart(product)}>Add to Cart</IonButton>
        </IonCardSubtitle> */}
      </IonCardHeader>
      {/* <IonCardContent>{product.description}</IonCardContent> */}

    </IonCard>
  );
};