//const baseURL = 'http://localhost/abisgourmetqc.ca/api/';
const baseURL = 'https://abisgourmetqc.ca/demo/api/';

export const RestApi = (url: string, data: Object, ...$params:any) => {
    return new Promise((resolve, reject) => {
        const config:object = {
            method: 'POST',
            // mode: 'cors',
            headers: {
                Token: 'J1+t$0@K6_M59a9+BJNZD9QF',
                Authorization: `Bearer ${$params[0]}`
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data),            
        };
        try {
            fetch(baseURL + url, config).then((response:any) => {
                if(response.status == 200){
                    return response.json();
                }else{
                    reject('Invalid reponse');
                }
            }).then((response:any) => { 
                if (response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response.message);
                }
            });
        } catch (error:any) {
            let message = typeof error.response !== "undefined" ? error.response.data.message : error.message;
            reject(message);
        }
    });
}