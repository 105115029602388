import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../contexts/AuthProvider';
import { useUtility } from '../../contexts/UtilityContext';
import { RestApi } from '../../services/RestApi';

export const Checkout: React.FC = () => {
 const { authInfo, cartInfo, resetCart } = useAuth();
 const [inputs, setInputs] = useState({ d_name: authInfo.user.name, d_email: authInfo.user.email, d_contact: '', d_address: authInfo.user.address, d_zipcode: '', d_city: '' });
 const { showLoading, hideLoading, showAlert, showToast } = useUtility();
 const history = useHistory();
 const onInputchange = (event: any) => {
  setInputs((prevState: any) => {
   return ({
    ...prevState,
    [event.target.name]: event.target.value
   });
  });
 };

 const doCheckout = () => {
  try {
   const cart: any = [];
   cartInfo.map((c: any) => {
    cart.push({ product_id: c.id, qty: c.qty });
   });
   showLoading();
   RestApi('checkout', { ...inputs, cart: cart }, authInfo.user.authorization).then((response: any) => {
    hideLoading();
    resetCart();
    history.push('/orders');
   }).catch((message) => {
    hideLoading();
    showAlert(message, 'Checkout');
   });
  } catch (err: any) {
   showToast(err.message);
  }
 }
 return (
  <IonPage>
   <IonHeader>
    <IonToolbar color="primary">
     <IonButtons slot="start"><IonMenuButton /></IonButtons>
     <IonTitle>Checkout</IonTitle>
    </IonToolbar>
   </IonHeader>
   <IonContent fullscreen>
    <h3 className='ion-text-center'>Delivery Address</h3>
    <IonItem>
     <IonLabel position="floating">Name</IonLabel>
     <IonInput type="text" name="d_name" placeholder="Name" onIonChange={onInputchange} value={inputs.d_name} required />
    </IonItem>
    <IonItem>
     <IonLabel position="floating">Email</IonLabel>
     <IonInput type="email" name="d_email" placeholder="Email" onIonChange={onInputchange} value={inputs.d_email} required />
    </IonItem>
    <IonItem>
     <IonLabel position="floating">Contact</IonLabel>
     <IonInput type="email" name="d_contact" placeholder="Contact Person" onIonChange={onInputchange} value={inputs.d_contact} required />
    </IonItem>
    <IonItem lines="full">
     <IonLabel position="floating">Address</IonLabel>
     <IonInput type="text" name="d_address" placeholder="Address" onIonChange={onInputchange} value={inputs.d_address} required />
    </IonItem>
    <IonItem lines="full">
     <IonLabel position="floating">Zipcode</IonLabel>
     <IonInput type="text" name="d_zipcode" placeholder="Zipcode" onIonChange={onInputchange} value={inputs.d_zipcode} required />
    </IonItem>
    <IonItem lines="full">
     <IonLabel position="floating">City</IonLabel>
     <IonInput type="text" name="d_city" placeholder="City" onIonChange={onInputchange} value={inputs.d_city} required />
    </IonItem>
    <IonButton onClick={doCheckout} color="primary" expand="block">Place Order</IonButton>
   </IonContent>
  </IonPage>
 );
};
