import { IonBackButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { NotFound } from '../../components/NotFound';
import { ProductGridView } from '../../components/ProductGridView';
import { useAuth } from '../../contexts/AuthProvider';
import { useUtility } from '../../contexts/UtilityContext';
import { ProductModel } from '../../models/ProductModel';
import { RestApi } from '../../services/RestApi';


export const Products: React.FC = () => {
  const params = useParams<{ id: string, name: string }>();
  const { authInfo } = useAuth();
  const [products, setProducts] = useState<ProductModel[]>([]);
  const { showLoading, hideLoading } = useUtility();
  const [isLoaded, setLoaded] = useState(false);
  useIonViewWillEnter(() => {
    showLoading();
    RestApi('products/' + params.id, { lang: 'en' }, authInfo.user.authorization).then(function (response: any) {
      setProducts(response);
      hideLoading();
      setLoaded(true);
    });
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/categories" />
          </IonButtons>
          <IonTitle>{params.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {isLoaded ? (products.length > 0 ? (<>
          {products.map(product => <ProductGridView key={product.id} product={product} />)}
        </>) : (<NotFound />)) : ''}
      </IonContent>
    </IonPage>
  );
};
