import { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext<any>({});
export const AuthProvider: React.FC = ({ children }) => {
    const [recoveryInfo, setRecoveryInfo] = useState<any>(() => {
        let initialValue = localStorage.getItem('recoveryInfo') || {
            forgotToken: null,
            verificationToken: null
        };
        if (typeof initialValue === 'string') {
            initialValue = JSON.parse(initialValue);
        }
        return initialValue;
    });
    const [cartInfo, setCartInfo] = useState<any>(() => {
        let initialValue = localStorage.getItem('cartInfo') || [];
        if (typeof initialValue === 'string') {
            initialValue = JSON.parse(initialValue);
        }
        return initialValue;
    });
    const [authInfo, setAuthInfo] = useState<any>(() => {
        let initialValue = localStorage.getItem('authInfo') || {
            authenticated: false,
            user: null
        };
        if (typeof initialValue === 'string') {
            initialValue = JSON.parse(initialValue);
        }
        return initialValue;
    });
    useEffect(() => {
        localStorage.setItem('authInfo', JSON.stringify(authInfo));
        localStorage.setItem('recoveryInfo', JSON.stringify(recoveryInfo));
        localStorage.setItem('cartInfo', JSON.stringify(cartInfo));
    }, [authInfo, recoveryInfo, cartInfo]);

    const recoveryForgotPassword = (token: string) => {
        setRecoveryInfo({
            ...recoveryInfo,
            forgotToken: token
        });
    };

    const recoveryVerification = (token: string) => {
        setRecoveryInfo({
            ...recoveryInfo,
            verificationToken: token
        });
    };

    const recoveryReset = () => {
        setAuthInfo({
            ...authInfo,
            forgotToken: null,
            verificationToken: null
        });
    };


    const authLogin = (user: any) => {
        setAuthInfo({
            ...authInfo,
            authenticated: true,
            user: user
        });
    };

    const authLogout = () => {
        setAuthInfo({
            ...authInfo,
            authenticated: false,
            user: null
        });
    };

    const addCart = (cart: any) => {
        
        let info = [...cartInfo];
        let flag = false;
        info.map((c, i) => {
            if(c.id == cart.id){
                info[i] = cart;
                flag = true;
            }
        });
        if(!flag){
            info.push(cart);
        }
        setCartInfo(info);
    };

    const removeCart = (id: number) => {
        let info = [...cartInfo];
        info.map((c, i) => {
            if(c.id == id){
                info.splice(i, 1);
            }
        });
        setCartInfo(info);
    };

    const resetCart = () => {
        setCartInfo([]);
    };

    const setUserInfo = (userInfo: Object) => {
        let user = authInfo.user;
        user = {...user, ...userInfo};
        setAuthInfo({
            ...authInfo,
            user: user
        });
    };

    let state = {
        authInfo, authLogin, authLogout, setUserInfo,
        recoveryInfo, recoveryForgotPassword, recoveryVerification, recoveryReset,
        cartInfo, addCart, removeCart, resetCart
    };

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
};
export const useAuth = () => useContext(AuthContext);