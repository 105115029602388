import { IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { trash } from 'ionicons/icons';
import { NotFound } from '../../components/NotFound';
import { useAuth } from '../../contexts/AuthProvider';

export const Cart: React.FC = () => {
 const { cartInfo, removeCart } = useAuth();

 return (
  <IonPage>
   <IonHeader>
    <IonToolbar color="primary">
     <IonButtons slot="start"><IonMenuButton /></IonButtons>
     <IonTitle>Cart</IonTitle>
    </IonToolbar>
   </IonHeader>
   <IonContent fullscreen>
    {cartInfo.length > 0 ? (<>
     <IonList>
      {cartInfo.map((cart: any) => <IonItem key={cart.id}>
       <IonAvatar slot="start"><img src={cart.image} /></IonAvatar>
       <IonLabel>
        <h2>{cart.product_name}</h2>
        <h3>Qty : {cart.qty}</h3>
        <p>{cart.description}</p>
       </IonLabel>
       <IonButton onClick={() => removeCart(cart.id)} slot="end" color="danger"> <IonIcon icon={trash} /></IonButton>
      </IonItem>)}
     </IonList>
     <IonButton routerLink="/checkout" color="primary" expand="block">Checkout</IonButton>
    </>) : <NotFound />}
   </IonContent>
  </IonPage>
 );
};
