import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonCol, IonImg, IonInput, IonItem, IonLabel, IonList, IonRow } from '@ionic/react';

import { OrderModel } from '../models/OrderModel';

export const OrderView: React.FC<{ order: OrderModel }> = ({ order }) => {
  return (
    <>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Order # {order.order_no}</IonCardTitle>
          <IonChip className="ion-float-end">Total Qty : {order.total_qty}</IonChip>
          <p>{order.order_date}</p>
        </IonCardHeader>
        <IonCardContent>

          {order.products.length > 0 ? (<>
            <IonList>
              {order.products.map((product) => <IonItem key={product.id}>
                <IonAvatar slot="start"><img src={product.image} /></IonAvatar>
                <IonLabel>
                  <h2>{product.product_name}</h2>
                  <h3>Qty : {product.qty}</h3>
                  <p>{product.description}</p>
                </IonLabel>
              </IonItem>)}
            </IonList>
          </>) : ''}
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Delivery Address</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel>Name</IonLabel>
            <h2>{order.d_name}</h2>
          </IonItem>
          <IonItem>
            <IonLabel>Email</IonLabel>
            <h2>{order.d_email}</h2>
          </IonItem>
          <IonItem>
            <IonLabel>Contact</IonLabel>
            <h2>{order.d_contact}</h2>
          </IonItem>
          <IonItem>
            <IonLabel>Address</IonLabel>
            <h2>{order.d_address}</h2>
          </IonItem>
          <IonItem>
            <IonLabel>Zipcode</IonLabel>
            <h2>{order.d_zipcode}</h2>
          </IonItem>
          <IonItem>
            <IonLabel>City</IonLabel>
            <h2>{order.d_city}</h2>
          </IonItem>
        </IonCardContent>
      </IonCard>
    </>
  );
};