import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';

export const ProductDetail: React.FC = () => {
 const { id } = useParams<{ id: any }>();

 return (
  <IonPage>
   <IonHeader>
    <IonToolbar color="primary">
     <IonTitle>Products</IonTitle>
    </IonToolbar>
   </IonHeader>
   <IonContent fullscreen>
    {id}
   </IonContent>
  </IonPage>
 );
};
