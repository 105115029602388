import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonFooter, IonPage, IonRippleEffect} from '@ionic/react';
// import './Auth.scss';

export const Page404: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen class="ion-padding">
        <div className="auth-container">
          <IonCard class="card">
            <IonCardHeader>
              <IonCardTitle class="ion-text-center card-title">404</IonCardTitle>
              <IonCardSubtitle class="ion-text-center">Page not found!</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <IonFooter class="ion-padding ion-no-border">
        <IonButton color="tertiary" className="ion-activatable ripple-parent" expand="block" href="/">GO TO HOME
          <IonRippleEffect type="unbounded"></IonRippleEffect>
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};