import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRippleEffect, IonRouterLink, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAuth } from '../../contexts/AuthProvider';
import { useUtility } from '../../contexts/UtilityContext';
import { RestApi } from '../../services/RestApi';
import './Auth.scss';

export const Register: React.FC = () => {

  const { authLogin } = useAuth();
  const [inputs, setInputs] = useState({ name: '', email: '', password: '', mobile: '', address: '' });
  const history = useHistory();
  const { showLoading, hideLoading, showAlert, showToast } = useUtility();
  const onInputchange = (event: any) => {
    setInputs((prevState: any) => {
      return ({
        ...prevState,
        [event.target.name]: event.target.value
      });
    });
  };


  const doLogin = () => {
    try {
      showLoading();
      RestApi('register', inputs).then((response: any) => {
        hideLoading();
        history.push('/auth/login');
      }).catch((message) => {
        hideLoading();
        showAlert(message, 'Register');
      });
    } catch (err: any) {
      showToast(err.message);
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle className="login-title">Register Account</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="auth-container">
          <IonCard class="card ion-margin-top">
            <IonCardHeader>
              <IonCardTitle class="ion-text-center">Fill the form to create new account</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem>
                <IonLabel position="floating">Name</IonLabel>
                <IonInput type="text" name="name" clearInput placeholder="Your Name" onIonChange={onInputchange} required />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput type="email" name="email" clearInput placeholder="Your Email" onIonChange={onInputchange} required />
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type="password" name="password" clearInput placeholder="Your password" onIonChange={onInputchange} required />
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="floating">Mobile No</IonLabel>
                <IonInput type="number" name="mobile" clearInput placeholder="Your Mobile No" onIonChange={onInputchange} required />
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="floating">Address</IonLabel>
                <IonInput type="text" name="address" clearInput placeholder="Your Address" onIonChange={onInputchange} required />
              </IonItem>
              <IonGrid class="ion-margin-top">
                <IonRow>
                  <IonCol size-sm="12" size-xs="12" class="ion-text-right">
                    Already have account?&nbsp;<IonRouterLink color="primary" href={'/auth/login'} class="ios ion-activatable hydrated">Sign in</IonRouterLink>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </div>
        <IonButton color="tertiary" className="ion-activatable ripple-parent" expand="block" onClick={doLogin}>Create Account
          <IonRippleEffect type="unbounded"></IonRippleEffect>
        </IonButton>
      </IonContent>
    </IonPage>
  );
};
