import { IonPage, useIonViewWillEnter } from '@ionic/react';
import { useHistory } from 'react-router';
import { useAuth } from '../../contexts/AuthProvider';

export const Logout: React.FC = () => {
  const { authLogout } = useAuth();
  const history = useHistory();
  useIonViewWillEnter(() => {
    authLogout();
    history.push('/');
  });
  return (<IonPage></IonPage>);
};
