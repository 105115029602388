import { IonBackButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { NotFound } from '../../components/NotFound';
import { OrderView } from '../../components/OrderView';
import { useAuth } from '../../contexts/AuthProvider';
import { useUtility } from '../../contexts/UtilityContext';
import { OrderModel } from '../../models/OrderModel';
import { RestApi } from '../../services/RestApi';


export const OrderDetail: React.FC = () => {
 const { authInfo } = useAuth();
 const { id } = useParams<{ id: string }>();
 const [order, setOrder] = useState<OrderModel>();
 const { showLoading, hideLoading } = useUtility();
 const [isLoaded, setLoaded] = useState(false);
 useIonViewWillEnter(() => {
  showLoading();
  RestApi('order/' + id, {}, authInfo.user.authorization).then(function (response: any) {
   setOrder(response);
   hideLoading();
   setLoaded(true);
  });
 });

 return (
  <IonPage>
   <IonHeader>
    <IonToolbar color="primary">
     <IonButtons slot="start">
      <IonBackButton text="" defaultHref="/orders" />
     </IonButtons>
     <IonTitle>Order Detail</IonTitle>
    </IonToolbar>
   </IonHeader>
   <IonContent fullscreen>
    {isLoaded ? (order ? (<OrderView order={order} />) : <NotFound />) : ''}
   </IonContent>
  </IonPage>
 );
};
