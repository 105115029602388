import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg } from '@ionic/react';
import { CategoryModel } from '../models/CategoryModel';

export const CategoryGridView: React.FC<{ category: CategoryModel }> = ({ category }) => {
  return (
    <IonCard routerLink={'/products/' + category.id + '/'+ category.category_name} key={category.id}>
      <IonImg src={category.image} />
      <IonCardHeader>
        {/* <IonCardSubtitle>{category.category_name}</IonCardSubtitle> */}
        <IonCardTitle>{category.category_name}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent dangerouslySetInnerHTML={{__html : category.description}} />
    </IonCard>
  );
};