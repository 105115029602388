import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote } from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { walletOutline, ribbonOutline, powerOutline, cardOutline, homeOutline, checkmarkCircleOutline, personOutline } from 'ionicons/icons';
import './Menu.css';
import { useAuth } from '../contexts/AuthProvider';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Dashboard',
    url: '/dashboard',
    iosIcon: homeOutline,
    mdIcon: homeOutline
  },
  {
    title: 'Products',
    url: '/categories',
    iosIcon: checkmarkCircleOutline,
    mdIcon: checkmarkCircleOutline
  },
  {
    title: 'Cart',
    url: '/cart',
    iosIcon: ribbonOutline,
    mdIcon: ribbonOutline
  },
  {
    title: 'Orders',
    url: '/orders',
    iosIcon: walletOutline,
    mdIcon: walletOutline
  },
  {
    title: 'Profile',
    url: '/profile',
    iosIcon: personOutline,
    mdIcon: personOutline
  },
  {
    title: 'Logout',
    url: '/auth/logout',
    iosIcon: powerOutline,
    mdIcon: powerOutline
  }
];

export const Menu: React.FC = () => {
  const location = useLocation();
  const { authInfo } = useAuth();
  
  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>{authInfo.user.name}</IonListHeader>
          <IonNote>{authInfo.user.email}</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={'a'+index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};