import { IonAvatar, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { idCard, trash } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { NotFound } from '../../components/NotFound';
import { useAuth } from '../../contexts/AuthProvider';
import { useUtility } from '../../contexts/UtilityContext';
import { RestApi } from '../../services/RestApi';

export const Profile: React.FC = () => {
 const { authInfo } = useAuth();

 return (
  <IonPage>
   <IonHeader>
    <IonToolbar color="primary">
     <IonButtons slot="start"><IonMenuButton /></IonButtons>
     <IonTitle>My Profile</IonTitle>
    </IonToolbar>
   </IonHeader>
   <IonContent fullscreen>
    <IonItem>
     <IonLabel position="floating">Name</IonLabel>
     <h2>{authInfo.user.name}</h2>
    </IonItem>
    <IonItem>
     <IonLabel position="floating">Email</IonLabel>
     <h2>{authInfo.user.email}</h2>
    </IonItem>
    <IonItem lines="full">
     <IonLabel position="floating">Address</IonLabel>
     <h2>{authInfo.user.address}</h2>
    </IonItem>
    <IonItem lines="full">
     <IonLabel position="floating">Mobile No</IonLabel>
     <h2>{authInfo.user.mobile}</h2>
    </IonItem>
    
   </IonContent>
   <IonFooter>
    <IonButton routerLink='/auth/logout'color="danger" expand="block">Log out</IonButton>
   </IonFooter>
  </IonPage>
 );
};
