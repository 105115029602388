import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { ProductDetail } from './pages/protected/ProductDetail';
import { Cart } from './pages/protected/Cart';
import { Checkout } from './pages/protected/Checkout';
import { Orders } from './pages/protected/Orders';
import { OrderDetail } from './pages/protected/OrderDetail';
import { Products } from './pages/protected/Products';
import { Categories } from './pages/protected/Categories';
import { useUtility, UtilityProvider } from './contexts/UtilityContext';
import { useAuth } from './contexts/AuthProvider';
import { AuthRoute, ProtectedRoute } from './AppRoutes';
import { Page404 } from './pages/Page404';
import { Menu } from './components/Menu';
import { ForgotPassword } from './pages/auth/ForgotPassword';
import { Verification } from './pages/auth/Verification';
import { NewPassword } from './pages/auth/NewPassword';
import { Logout } from './pages/auth/Logout';
import { Login } from './pages/auth/Login';
import { Register } from './pages/auth/Register';
import { Profile } from './pages/protected/Profile';
import { useEffect } from 'react';
import { Plugins, Capacitor } from "@capacitor/core";

setupIonicReact();

const App: React.FC = () => {
  const { authInfo } = useAuth();
  const { showConfirm } = useUtility();
  const protectedRoutes = [
    { component: Categories, path: "/dashboard" },
    { component: Categories, path: "/categories" },
    { component: Products, path: "/products/:id/:name" },
    { component: ProductDetail, path: "/product/:id" },
    { component: Cart, path: "/cart" },
    { component: Checkout, path: "/checkout" },
    { component: Orders, path: "/orders" },
    { component: OrderDetail, path: "/order/:id" },
    { component: Profile, path: "/profile" },
    { component: Logout, path: "/auth/logout" },
  ];
  const authRoutes = [
    { component: Login, path: "/auth/login" },
    { component: Register, path: "/auth/register" },
    { component: ForgotPassword, path: "/auth/forgot-password" },
    { component: Verification, path: "/auth/verification" },
    { component: NewPassword, path: "/auth/new-password" },
  ];
  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e:any) => {
        if (window.location.pathname === "/" || window.location.pathname === "/auth/login" || window.location.pathname === "/dashboard") {
          if(window.confirm("Are you want to exit?")) {
            Plugins.App.exitApp();
          }
        }
      });
    }
  }, []);
  return (
    <IonApp>
      <UtilityProvider>
        <IonReactRouter>
          {authInfo?.authenticated !== true ? (
            <IonRouterOutlet>
              {protectedRoutes.map((prop, index) => {
                return (
                  <ProtectedRoute key={index} {...prop} />
                );
              })}

              {authRoutes.map((prop, index) => {
                return (
                  <AuthRoute key={index} {...prop} />
                );
              })}
              <Route exact path="/">
                <Redirect to="/auth/login" />
              </Route>
              <Route component={Page404} />
            </IonRouterOutlet>
          ) : (
            <IonSplitPane contentId="main">
              <Menu />
              <IonRouterOutlet id="main">
                {protectedRoutes.map((prop, index) => {
                  return (
                    <ProtectedRoute key={index} {...prop} />
                  );
                })}
                {authRoutes.map((prop, index) => {
                  return (
                    <AuthRoute key={index} {...prop} />
                  );
                })}
                <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
                <Route component={Page404} />
              </IonRouterOutlet>
            </IonSplitPane>
          )}
        </IonReactRouter>
      </UtilityProvider>
    </IonApp>
  );
};

export default App;
