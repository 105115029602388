import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRippleEffect, IonRouterLink, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../contexts/AuthProvider';
import { useUtility } from '../../contexts/UtilityContext';
import { RestApi } from '../../services/RestApi';
import './Auth.scss';

export const ForgotPassword: React.FC = () => {

  const { recoveryForgotPassword } = useAuth();
  const [inputs, setInputs] = useState({ email: '' });
  const history = useHistory();
  const { showLoading, hideLoading, showAlert, showToast } = useUtility();
  const onInputchange = (event: any) => {
    setInputs((prevState: any) => {
      return ({
        ...prevState,
        [event.target.name]: event.target.value
      });
    });
  };

  const doForgotPassword = () => {
    try {
      showLoading();
      RestApi('forgot_password', inputs).then((response: any) => {
        hideLoading();
        recoveryForgotPassword(response.forgotToken);
        history.push('/auth/verification');
      }).catch((message) => {
        hideLoading();
        showAlert(message, 'Forgot Password');
      });
    } catch (err: any) {
      showToast(err.message);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="login-title">
          Password Reset
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen class="ion-padding">
        <div className="auth-container">
          <IonCard class="card" ion-margin-top>
            <IonCardHeader>
              <IonCardTitle class="ion-text-center">Type your email to reset your password</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput type="email" name="email" clearInput placeholder="Your Email" onIonChange={onInputchange} required />
              </IonItem>
              <IonGrid class="ion-margin-top">
                <IonRow>
                  <IonCol class="ion-text-right">
                    <IonRouterLink color="primary" href={'/auth/login'} class="ios ion-activatable hydrated">Login</IonRouterLink>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <IonFooter class="ion-padding ion-no-border">
        <IonButton color="tertiary" className="ion-activatable ripple-parent" expand="block" onClick={doForgotPassword}>RESET
          <IonRippleEffect type="unbounded"></IonRippleEffect>
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};