import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./contexts/AuthProvider";

export const ProtectedRoute: React.FC = ({ component: Component, ...rest }: any) => {
    const { authInfo } = useAuth();
    return (
        <Route {...rest} render={(props) => authInfo?.authenticated ? (<Component {...props} />) : (<Redirect to="/" />)} />
    );
}

export const AuthRoute: React.FC = ({ component: Component, ...rest }: any) => {
    const { authInfo } = useAuth();
    return (
        <Route {...rest} render={(props) => !authInfo?.authenticated ? (<Component {...props} />) : (<Redirect to="/dashboard" />)} />
    );
}