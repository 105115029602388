import { IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { useState } from 'react';
import { NotFound } from '../../components/NotFound';
import { useAuth } from '../../contexts/AuthProvider';
import { useUtility } from '../../contexts/UtilityContext';
import { OrderModel } from '../../models/OrderModel';
import { RestApi } from '../../services/RestApi';


export const Orders: React.FC = () => {
 const { authInfo } = useAuth();
 const [order, setOrder] = useState<OrderModel>();
 const [orders, setOrders] = useState<OrderModel[]>([]);
 const { showLoading, hideLoading } = useUtility();
 const [isLoaded, setLoaded] = useState(false);
 useIonViewWillEnter(() => {
  showLoading();
  RestApi('orders', {}, authInfo.user.authorization).then(function (response: any) {
   setOrders(response);
   hideLoading();
   setLoaded(true);
  });
 });

 return (
  <IonPage>
   <IonHeader>
    <IonToolbar color="primary">
     <IonButtons slot="start"><IonMenuButton /></IonButtons>
     <IonTitle>Order History</IonTitle>
    </IonToolbar>
   </IonHeader>
   <IonContent fullscreen>
    {isLoaded ? (orders.length > 0 ? (<IonList>
     {orders.map(order => <IonItem key={order.id} routerLink={'/order/' + order.id}>
      <IonLabel>
       <h2>Order # {order.order_no}</h2>
       <h3>Qty : {order.total_qty}</h3>
       <p>{order.order_date}</p>
      </IonLabel>
     </IonItem>)}
    </IonList>) : (<NotFound />)) : ''}
   </IonContent>
  </IonPage>
 );
};
