import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonRippleEffect, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../contexts/AuthProvider';
import { useUtility } from '../../contexts/UtilityContext';
import { RestApi } from '../../services/RestApi';
import './Auth.scss';

export const NewPassword: React.FC = () => {
  const { showToast, showAlert, showLoading, hideLoading } = useUtility();
  const { recoveryInfo, recoveryReset } = useAuth();
  const [inputs, setInputs] = useState({ ...recoveryInfo, password: '', confirm_password: '' });
  const history = useHistory();
  const onInputchange = (event: any) => {
    setInputs((prevState: any) => {
      return ({
        ...prevState,
        [event.target.name]: event.target.value
      });
    });
  };

  const doChangePassword = () => {
    try {
      showLoading();
      RestApi('change_password', inputs).then((response: any) => {
        hideLoading();
        recoveryReset();
        history.push('/auth/login');
      }).catch((message) => {
        hideLoading();
        showAlert(message, 'New Password');
      });
    } catch (err: any) {
      showToast(err.message);
    }
  }
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="login-title">
          Choose Password
          </IonTitle>
        </IonToolbar>
      </IonHeader>
        <div className="auth-container">
          <IonCard class="card ion-margin-top">
            <IonCardHeader>
              <IonCardTitle class="ion-text-center">Fill your desire password</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type="password" name="password" clearInput placeholder="New Password" onIonChange={onInputchange} />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Confirm Password</IonLabel>
                <IonInput type="password" name="confirm_password" clearInput placeholder="Type password again" onIonChange={onInputchange} />
              </IonItem>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <IonFooter class="ion-padding ion-no-border">
        <IonButton color="tertiary" className="ion-activatable ripple-parent" expand="block" onClick={doChangePassword}>SUBMIT
          <IonRippleEffect type="unbounded"></IonRippleEffect>
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};
