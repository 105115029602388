import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonFooter, IonHeader, IonInput, IonMenuButton, IonPage, IonRippleEffect, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../contexts/AuthProvider';
import { useUtility } from '../../contexts/UtilityContext';
import { RestApi } from '../../services/RestApi';
import './Auth.scss';

export const Verification: React.FC = () => {
  const { recoveryInfo, recoveryVerification } = useAuth();
  const [inputs, setInputs] = useState({ ...recoveryInfo, otp: '' });
  const history = useHistory();
  const { showLoading, hideLoading, showAlert, showToast } = useUtility();
  const onInputchange = (event: any) => {
    setInputs((prevState: any) => {
      return ({
        ...prevState,
        [event.target.name]: event.target.value
      });
    });
  };

  const doVerification = () => {
    try {
      showLoading();
      RestApi('verification', inputs).then((response: any) => {
        hideLoading();
        recoveryVerification(response.verificationToken);
        history.push('/auth/new-password');
      }).catch((message) => {
        hideLoading();
        showAlert(message, 'OTP');
      });
    } catch (err: any) {
      showToast(err.message);
    }
  }
  return (
    <IonPage>
      <IonContent fullscreen>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="login-title">
            Verification
          </IonTitle>
        </IonToolbar>
      </IonHeader>
        <div className="auth-container">
          <IonCard class="card ion-margin-top">
            <IonCardHeader>
              <IonCardTitle class="ion-text-center">Enter 4 digits email verfication code</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonInput type="number" name="otp" class="form-control-code" placeholder="••••" maxlength={4} onIonChange={onInputchange} />
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <IonFooter class="ion-padding ion-no-border">
        <IonButton color="tertiary" className="ion-activatable ripple-parent" expand="block" onClick={doVerification}>VERIFY
          <IonRippleEffect type="unbounded"></IonRippleEffect>
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};
