import { IonButtons, IonContent, IonHeader, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { useState } from 'react';
import { CategoryGridView } from '../../components/CategoryGridView';
import { NotFound } from '../../components/NotFound';
import { useAuth } from '../../contexts/AuthProvider';
import { useUtility } from '../../contexts/UtilityContext';
import { CategoryModel } from '../../models/CategoryModel';
import { RestApi } from '../../services/RestApi';


export const Categories: React.FC = () => {
 const { authInfo } = useAuth();
 const [ categories, setCategories ] = useState<CategoryModel[]>([]);
 const { showLoading, hideLoading } = useUtility();
 const [isLoaded, setLoaded] = useState(false);
 useIonViewWillEnter(() => {
  showLoading();
  RestApi('categories', { lang: 'en' }, authInfo.user.authorization).then(function (response: any) {
   setCategories(response);
   hideLoading();
   setLoaded(true);
  });
 });

 return (
  <IonPage>
   <IonHeader>
    <IonToolbar color="primary">
    <IonButtons slot="start"><IonMenuButton /></IonButtons>
     <IonTitle>Products</IonTitle>
    </IonToolbar>
   </IonHeader>
   <IonContent fullscreen>
    {isLoaded ? (categories.length > 0 ? (<>
     {categories.map(cat => <CategoryGridView key={cat.id} category={cat} />)}
    </>) : <NotFound />) : ''}
   </IonContent>
  </IonPage>
 );
};
