import { useIonAlert, useIonLoading, useIonToast } from "@ionic/react";
import { createContext, useContext } from "react";

const UtilityContext = createContext<any>({});
export const UtilityProvider: React.FC = ({ children }) => {

  const [showIonToast, hideIonToast] = useIonToast();
  const [showIonLoading, hideIonLoading] = useIonLoading();
  const [showIonAlert] = useIonAlert();

  const showAlert = (message: string, header: string) => {
    showIonAlert({
      header: header,
      message: message,
      buttons: [
        { text: 'Ok' },
      ],
    });
  };

  const showConfirm = (message: string, header: string, params :any) => {
    return new Promise((resolve) => {
    showIonAlert({
      header: header,
      message: message,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Yes',
          handler: () => {
            resolve(params);
          }
        }
      ],
    });
  });
  };

  const showToast = (message: string) => {
    showIonToast({
      buttons: [{
        icon: 'close',
        handler: () => hideIonToast()
      }],
      message: message,
      duration: 4000,
      color: "danger",
      position: "bottom"
    });
  };

  const showLoading = () => {
    showIonLoading({
      message: 'Please wait...',
      onDidDismiss: hideIonLoading,
    });
  };

  const hideLoading = () => {
    hideIonLoading();
  };
  const state = {
    showAlert, showConfirm, showToast, showLoading, hideLoading
  };

  return <UtilityContext.Provider value={state}>
    {children}
  </UtilityContext.Provider>
};
export const useUtility = () => useContext(UtilityContext);